import { tns } from "tiny-slider/src/tiny-slider"


window.addEventListener("DOMContentLoaded", () => {
  const burger = document.querySelector('.burger');
  const globalMenu = document.querySelector('#global-menu');
  const overlay = document.querySelector('.overlay');
  const toggleNavbar = () => {
    burger.classList.toggle('is-active');
    globalMenu.classList.toggle('is-active');
    overlay.classList.toggle('is-active');
  }
  burger.addEventListener('click', () => {
    toggleNavbar()
  })
  overlay.addEventListener('click', () => {
    toggleNavbar()
  })

  if (document.querySelector('.categories-list')) {
    const el = document.querySelector('.categories-list');
    const limit = el.dataset.limit;
    const priority = el.dataset.priority 
    const togglerElement = document.querySelector(el.dataset.more);
    const toggler = ((toggled = true) => {
      return () => {
        [].forEach.call(el.querySelectorAll('li'), (item, index) => {
          if (toggled && 
              (priority && 
                !item.classList.contains('active') 
                && !item.classList.contains(priority)
                || (!priority && index > limit -1)
              )
          ) {
            item.style.display = 'none'
          } else {
            item.style.display = 'flex'
          }
        })
        toggled ? togglerElement.classList.remove('triangle-rotate-180') : togglerElement.classList.add('triangle-rotate-180')
        // inverse for next iteration
        toggled = !toggled;
      }
    })()
    toggler();
    togglerElement.addEventListener('click', toggler);
  }

  if (document.querySelector('.categories-carousel')) {
    tns({
      container: '.categories-carousel',

      mouseDrag: true,
      nav: false,
      slideBy: 3,
      responsive: {
        0: {
          items: 3,
          slideBy: 2
        },
        640: {
          items: 5,
          slideBy: 2
        },
        1000: {
          items: 7,
          gutter: 10,
        }
      }
    });
  }
  if (document.querySelector('.item-gallery')) {
    tns({
      container: '.item-gallery',
      mouseDrag: true,
      nav: false,
      controlsPosition: 'bottom'
    })
  }
  [].forEach.call(document.querySelectorAll('.cutted-content'), (el) => {
    const desiredHeight = el.dataset.height || '80px'
    if (parseInt(getComputedStyle(el).height) > parseInt(desiredHeight)) {
      el.classList.add('collapsed')
      el.style.height = desiredHeight
      const moreButton = document.createElement('a')
      moreButton.innerText = 'Подробнее'
      moreButton.classList.add('more')
      el.after(moreButton)
      moreButton.addEventListener('click', (e) => {
        e.preventDefault();
        el.style.height = 'auto'
        el.classList.remove('collapsed')
        e.currentTarget.remove()
      }, {once: true})
    }
  });

  [].forEach.call(document.querySelectorAll('[data-target]'), (el) => {
    const target = el.dataset.target
    el.addEventListener('click', (e) => {
      e.preventDefault()
      if (document.querySelector(target)) {
        const targetEl = document.querySelector(target)
        if (!targetEl.querySelector('.target-scroller')) {
          const sc = document.createElement('div');
          targetEl.style.position = 'relative'
          sc.style.position = 'absolute'
          sc.style.top = '-90px'
          sc.classList.add('target-scroller')
          targetEl.append(sc)
        }
        targetEl.querySelector('.target-scroller').scrollIntoView({behavior: 'smooth'});
      }
    })
  })

  if (document.querySelector('.build-sort')) {
    if (window.location.search) {
      const opts = window.location.search
                .substr(1)
                .split("&")
                .map(e => e.split('='))
                .filter(e => ['price', 'date'].includes(e[0]))
      if (opts.length > 0) {
        const opt = opts[0] // get first element
        const selectedIndex = document.querySelector(`.build-sort option[value='${opt.join('-')}']`).index
        document.querySelector('.build-sort').selectedIndex = selectedIndex
      }
    }
    document.querySelector('.build-sort').addEventListener('change', (e) => {
      const path = e.currentTarget.value.split('-').join('=')
      window.location.search = path
    })
  }
})